import { Box, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { memo, useContext, useEffect } from 'react';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import {
  GridItemKpiContainer,
  KpiCardGridContainer,
  KpiData,
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { TEXT } from 'shared/constants/text';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { theme } from 'styles/theme';

import { ServiceResult } from 'shared/models/serviceResult.model';
import { formatWithPercentage } from 'routes/environmental-monitor/mappers/vessel-kpi-data.mapper';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { IPlannedItineraryResults } from 'routes/environmental-monitor/models/plannedItinerary.model';
import { formatNumber } from 'shared/utils/float-utils';
import { typography } from 'styles/typography';
import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import { useGetFeatureFlag } from 'shared/services/featureFlag.service';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';

type kpis =
  | 'ciiRating'
  | 'ciiEOYForecast'
  | 'aer'
  | 'eeoi'
  | 'voyagevsYTD'
  | 'eua'
  | 'ghgMrv'
  | 'fuelEu';
type KpiDataDomain = {
  [id in kpis]: KpiData;
};

export const useGetEnvironmentalKPISummary = (
  plannedItineraryResults: ServiceResult<IPlannedItineraryResults> | undefined
): ServiceResult<KpiDataDomain> => {
  if (!plannedItineraryResults) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
  const results = plannedItineraryResults.data;
  const averageSpeedSign =
    results?.avgSpeedVsYtdAvgSpeed && results?.avgSpeedVsYtdAvgSpeed >= 0
      ? '+'
      : '';

  const idleTimeComparisonSign =
    results?.idlePercentageVsYtdIdlePercentage &&
    results?.idlePercentageVsYtdIdlePercentage >= 0
      ? '+'
      : '';

  try {
    const data: KpiDataDomain = {
      ciiRating: {
        primaryValue: results?.ciiRating.value.toString() ?? NA_VALUE_INDICATOR,
        secondaryValue: formatNumber(results?.estimatedCii, 2),
        tertiaryValue: formatNumber(results?.requiredCii, 2),
        quaternaryValue: formatWithPercentage(results?.ciiDeviation.value),
        status: results?.ciiDeviation.status ?? 'unknown',
      },
      ciiEOYForecast: {
        primaryValue: results?.eoyCiiRating ?? NA_VALUE_INDICATOR,
        secondaryValue: formatNumber(results?.eoyCii, 2),
        tertiaryValue: formatNumber(results?.requiredCii, 2),
        quaternaryValue: formatWithPercentage(results?.eoyCiiPercentage),
        status: results?.eoyCiiDeviation.status ?? 'unknown',
      },
      aer: {
        primaryValue: formatWithPercentage(results?.ciiDeviation.value),
        secondaryValue: formatNumber(results?.estimatedCii, 2),
        tertiaryValue: formatNumber(results?.requiredCii, 2),
        status: results?.ciiDeviation.status ?? 'unknown',
      },
      eeoi: {
        primaryValue: formatWithPercentage(results?.eeoiDeviation.value),
        secondaryValue: formatNumber(results?.eeoi, 2),
        tertiaryValue: formatNumber(results?.eeoiTarget, 2),
        status: results?.eeoiDeviation.status ?? 'unknown',
      },

      voyagevsYTD: {
        primaryValue: `${averageSpeedSign}${formatNumber(
          results?.avgSpeedVsYtdAvgSpeed ?? 0,
          0
        )} ${TEXT.UNIT_MEASUREMENT.KNOTS}`,
        secondaryValue: `${formatNumber(results?.avgSpeed ?? 0, 0)} ${
          TEXT.UNIT_MEASUREMENT.KNOTS
        }`,
        tertiaryValue: formatWithPercentage(results?.idlePercentage ?? 0),
        quaternaryValue: `${idleTimeComparisonSign}${formatWithPercentage(
          results?.idlePercentageVsYtdIdlePercentage ?? 0
        )}`,
        status: 'none',
      },
      eua: {
        primaryValue: formatNumber(results?.euaDeviation),
        secondaryValue: formatNumber(results?.eua, 2),
        tertiaryValue: formatNumber(results?.euaPlanned, 2),
        quaternaryValue: '', // not showing in UI
        status: results?.euaStatus.status ?? 'unknown',
      },
      ghgMrv: {
        primaryValue: formatNumber(results?.ghgMrv, 2),
        status: results?.ghgStatus.status ?? 'unknown',
      },
      fuelEu: {
        primaryValue: formatNumber(results?.ghgIntensity, 2),
        secondaryValue: formatNumber(results?.complianceBalance, 2),
        tertiaryValue: formatNumber(results?.fuelEUPenalty, 2),
        status: results?.fuelEuStatus.status ?? 'unknown',
      },
    };
    const loading = plannedItineraryResults.loading;
    const error = plannedItineraryResults.error;

    return {
      data,
      loading,
      error,
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};

export const EnvironmentalKPISummary = ({
  plannedItineraryResults,
}: {
  readonly plannedItineraryResults:
    | ServiceResult<IPlannedItineraryResults>
    | undefined;
}) => {
  const { data, loading, error } = useGetEnvironmentalKPISummary(
    plannedItineraryResults
  );

  const emptyKpiData: KpiData = {
    primaryValue: NA_VALUE_INDICATOR,
    secondaryValue: NA_VALUE_INDICATOR,
    tertiaryValue: NA_VALUE_INDICATOR,
    quaternaryValue: NA_VALUE_INDICATOR,
    status: 'unknown',
  };
  const CIIRatingCardProps: StatusKpiCardProps = {
    title: 'CII Rating',
    footerType: [{ title: 'Estimated' }, { title: 'Required' }],
    kpiData: data?.ciiRating ?? emptyKpiData,
    units: data?.ciiRating?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
  };

  const EEOICardProps: StatusKpiCardProps = {
    title: 'EEOI',
    footerType: [{ title: 'Estimated' }, { title: 'Target' }],
    kpiData: data?.eeoi ?? emptyKpiData,
    footerUnits: TEXT.FORMULAS.EEOI,
    sectionVisibility: { footer: true },
  };

  const CIIEOYForecastCardProps: StatusKpiCardProps = {
    title: 'CII End of Year Forecast',
    footerType: [{ title: 'Estimated EOY' }, { title: 'Required' }],
    kpiData: data?.ciiEOYForecast ?? emptyKpiData,
    units: data?.ciiEOYForecast?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
    fixedTimeline: true,
  };

  const VoyageVsYTDCardProps: StatusKpiCardProps = {
    title: 'Voyage vs Year to Date',
    footerType: [
      {
        title: 'Average Speed',
        unitLabel: data?.voyagevsYTD?.primaryValue
          ? `${data?.voyagevsYTD?.primaryValue}`
          : NA_VALUE_INDICATOR,
      },
      {
        title: 'Idle Time',
        unitLabel: data?.voyagevsYTD.quaternaryValue
          ? `${data?.voyagevsYTD.quaternaryValue}`
          : NA_VALUE_INDICATOR,
      },
    ],
    sectionVisibility: { primaryValue: false, footer: true },
    kpiData: data?.voyagevsYTD
      ? { ...data?.voyagevsYTD, primaryValue: '', status: 'none' }
      : emptyKpiData,
  };

  const AERCardProps: StatusKpiCardProps = {
    title: 'AER',
    footerType: [{ title: 'Estimated' }, { title: 'Required' }],
    kpiData: data?.aer ?? emptyKpiData,
    units: data?.aer?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
  };

  const EuaAgainstPlannedProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Planned',
    footerType: 'eua-planned',
    kpiData: data?.eua ?? emptyKpiData,
    sectionVisibility: { footer: true },
    units: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
  };

  const EuaNoPlannedProps: StatusKpiCardProps = {
    title: 'EUA',
    footerType: 'default-none',
    kpiData: data?.eua ?? emptyKpiData,
  };

  const GhgMrvCardProps: StatusKpiCardProps = {
    title: 'Greenhouse Gas (GHG) Emission',
    titleAlignment: 'center',
    footerType: (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography
          sx={{ fontSize: typography.fontSize['10'], lineHeight: '100%' }}
        >
          {formatNumber(data?.ghgMrv.primaryValue, 2)}
        </Typography>
        <Box fontSize={'medium'} sx={{ color: 'gray' }}>
          tCO₂eq
        </Box>
        <Box sx={{ mt: 3 }}>MRV, Tank to Wake</Box>
      </Box>
    ),
    kpiData: {
      primaryValue: '',
      status: data?.ghgMrv.primaryValue ? Status.graph : Status.none,
    },
    hideFooterDivider: true,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
  };
  const FuelEuCardProps: StatusKpiCardProps = {
    title: 'GHG Intensity',
    footerType: [{ title: 'Compliance Balance' }, { title: 'FuelEU Penalty' }],
    kpiData: data?.fuelEu ?? emptyKpiData,
    units: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE,
    footerUnits: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ,
    footerUnits2: TEXT.CURRENCY.EURO,
    footerUnit2BeforeValue: true,
    sectionVisibility: { footer: true },
  };
  const euaPlannedValue =
    data?.eua.tertiaryValue &&
    data?.eua.tertiaryValue.toString().length > 0 &&
    data?.eua.tertiaryValue !== NA_VALUE_INDICATOR
      ? data?.eua.tertiaryValue
      : null;

  const hasPlannedValue: boolean = euaPlannedValue !== null;

  if (data && !hasPlannedValue) {
    data.eua.primaryValue = formatNumber(data?.eua.secondaryValue, 0);
  }

  const GhgMrvKpiFeatureEnabled = useGetFeatureFlag(FEATURE_FLAG.MRV_PART_2)
    .data?.isEnabled;
  const plannedItineraryFeatureEnabled = useGetFeatureFlag(
    FEATURE_FLAG.GHG_MRV_PLANNED_ITINERARY
  ).data?.isEnabled;
  const fuelEuPlannedItineraryKpiEnabled = useGetFeatureFlag(
    FEATURE_FLAG.VESSEL_OVERVIEW_FUEL_EU_PLANNED_ITINERARY_KPI
  ).data?.isEnabled;
  const ghgMrvCard =
    GhgMrvKpiFeatureEnabled && plannedItineraryFeatureEnabled
      ? [GhgMrvCardProps]
      : [];

  let kpiDataOthers = [
    AERCardProps,
    CIIRatingCardProps,
    EEOICardProps,
    CIIEOYForecastCardProps,

    // small cards
    VoyageVsYTDCardProps,
    hasPlannedValue ? EuaAgainstPlannedProps : EuaNoPlannedProps,
  ];
  if (fuelEuPlannedItineraryKpiEnabled) {
    kpiDataOthers = kpiDataOthers.concat(FuelEuCardProps);
  }
  const kpiData = kpiDataOthers.concat(ghgMrvCard);

  if (loading) {
    return <KpiCardSkeleton kpiCards={kpiData} />;
  }

  if (error) {
    return <KpiCardError kpiCards={kpiData} />;
  }

  return <AlertTableWarningMemo kpiData={kpiData} />;
};

const AlertTableWarningMemo = memo(function (props: {
  kpiData: StatusKpiCardProps[];
}) {
  const { setCardData } = useContext(ExcelExportContext);
  useEffect(() => {
    setCardData(props.kpiData);
  }, [props.kpiData, setCardData]);

  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant='h3'
        paragraph
        sx={{
          mb: 0,
          mt: 3,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Environmental KPI Summary
      </Typography>
      <KpiCardGridContainer>
        {props.kpiData.map((cardProps, _) => (
          <GridItemKpiContainer key={cardProps.title}>
            <StatusKpiCard {...cardProps} />
          </GridItemKpiContainer>
        ))}
      </KpiCardGridContainer>
    </ThemeProvider>
  );
});
