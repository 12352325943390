import { UTCDate } from 'shared/utils/date-utc-helper';

export const getYAxisTicks = (dataMin: number, dataMax: number): number[] => {
  const range = dataMax - dataMin;
  const ticks = [dataMin];

  const idealTickCount = Math.min(Math.max(Math.floor(range / 50), 2), 10); // Between 2 and 10 ticks
  const step = Math.ceil(range / idealTickCount);

  for (let i = 1; i <= idealTickCount; i++) {
    const tickValue = dataMin + i * step;

    if (tickValue > dataMax) break;

    if (tickValue <= dataMax) {
      ticks.push(tickValue);
    }
  }

  return ticks;
};

export const getXAxisTicks = (): number[] => {
  const currentYear = new UTCDate().year!;
  const ticks = [];
  for (let i = 0; i < 12; i++) {
    ticks.push(UTCDate.create(currentYear, i, 1, 0, 0, 0).unixTime!);
  }
  ticks.push(UTCDate.create(currentYear, 11, 31, 0, 0, 0).unixTime!);
  return ticks;
};
