import { UTCDate } from 'shared/utils/date-utc-helper';
import { theme } from 'styles/theme';
import {
  ChartsItemContentProps,
  LineSeriesType,
  ScatterSeriesType,
  ScatterValueType,
} from '@mui/x-charts-pro';
import { MuiChartContainerWithLegend } from 'shared/components/mui-chart/container.component';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { TEXT } from 'shared/constants/text';
import { Box } from '@mui/system';
import { nameof } from 'shared/components/datagrid';
import { useGetFleetFuelEuGhgIntensityChart } from 'routes/environmental-monitor/services/fleet.service';
import { useContext, useEffect, useState } from 'react';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';
import { FuelEuGhgIntensityTrendChartKeyItems } from 'routes/environmental-monitor/view-models/fuel-eu-ghg-intensity-usage-trend-chart.viewModel';
import {
  AreaBand,
  AreaDefinition,
} from 'shared/components/mui-chart/background/draw-background.component';
import { ChartLegend } from '../vessel-detail/eua/eua-ratings-mui-chart.component';
import { TrendChartDataset } from 'routes/environmental-monitor/view-models/trend-chart.viewModel';
import { fuelEuGhgIntensityUsageTooltip } from '../tooltip/fuel-eu-ghg-intensity-usage-trend-chart-tooltip.component';
import { FuelEUGhgIntensityChartProps } from 'routes/environmental-monitor/models/fleet.model';

export const FleetFuelEUGhgIntensityChart = () => {
  const { imoNumbers, utcDateRange } = useContext(NavBarContext);
  const { featureFlags } = useFeatureToggleContext();
  const [chartDataset, setChartDataset] = useState<
    FuelEUGhgIntensityChartProps[]
  >([]);
  const [areas, setAreas] = useState<Array<AreaDefinition>>([]);

  const fleetFuelEuGhgIntensityChartFlag =
    featureFlags[
      FEATURE_FLAG.FLEETOVERVIEW_FUEL_EU_GREENHOUSE_GAS_INTENSITY_TREND_CHART
    ];

  const fleetFuelEuGhgIntensityChart = useGetFleetFuelEuGhgIntensityChart(
    utcDateRange,
    imoNumbers
  );
  const tempChartDataset = fleetFuelEuGhgIntensityChart.data;
  const loading = fleetFuelEuGhgIntensityChart.loading;

  useEffect(() => {
    if (
      loading === false &&
      tempChartDataset &&
      fleetFuelEuGhgIntensityChartFlag
    ) {
      const myData = new TrendChartDataset(tempChartDataset);
      const dataset = myData.rangeDataset;
      const bandAreas = myData.getBandAreas();
      setChartDataset(dataset);
      setAreas(bandAreas);
    }
  }, [loading, utcDateRange, imoNumbers, fleetFuelEuGhgIntensityChartFlag]);

  const bands: AreaBand[] = [
    {
      type: 'area',
      axisId: 'band-axis',
      showLine: true,
      areas: areas,
      lineColor: FuelEuGhgIntensityTrendChartKeyItems.ghgIntensityTarget.fill,
      backgroundColor: theme.colors?.eRating,
    },
  ];

  const intensityTargetDataPoints: ScatterValueType[] = chartDataset.map(
    (item, index) => ({
      x: item?.date?.getTime() ?? 0,
      y: item.intensityTarget ?? 0,
      id: index,
    })
  );

  // Return null if the feature flag is disabled
  if (!fleetFuelEuGhgIntensityChartFlag) return null;
  const chartProps = nameof<FuelEUGhgIntensityChartProps>;
  const seriesDate: (LineSeriesType | ScatterSeriesType)[] = [
    {
      id: chartProps('intensity'),
      type: 'line',
      dataKey: chartProps('intensity'),
      label: 'GHG Intensity',
      highlightScope: { highlighted: 'item', faded: 'global' },
      color: FuelEuGhgIntensityTrendChartKeyItems.ghgIntensity.fill,
    },
    {
      id: chartProps('intensityTarget'),
      type: 'scatter',
      markerSize: 20,
      color: 'transparent',
      data: intensityTargetDataPoints,
    },
  ];

  const yearFormatter = (date: Date) => {
    return new UTCDate(date).formatMY();
  };

  const yAxisTitle = `GHG Intensity (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE})`;

  // get the max value for the left y-axis
  const maxIntensity = Math.max(...chartDataset.map((item) => item.intensity));
  const maxIntensityTarget = Math.max(
    ...chartDataset.map((item) => item.intensityTarget)
  );
  const maxLeftYValue = Math.max(maxIntensity, maxIntensityTarget) + 10; // add buffer to the max value

  const intensityTargetLegend: ChartLegend = {
    label: 'GHG Intensity Target',
    color: FuelEuGhgIntensityTrendChartKeyItems.ghgIntensityTarget.fill,
  };

  return (
    <CardContainer title={'FuelEU Greenhouse Gas (GHG) Intensity Trend'}>
      <Box component={'section'} sx={{ pl: 1, mb: 1 }}>
        <MuiChartContainerWithLegend
          background={{ bands: bands }}
          margin={{ right: 40 }}
          dataset={chartDataset}
          series={seriesDate}
          loading={loading}
          error={fleetFuelEuGhgIntensityChart.error}
          tooltip={{
            content: (params) =>
              fuelEuGhgIntensityUsageTooltip(
                params as ChartsItemContentProps<any>,
                chartDataset
              ),
          }}
          xAxis={[
            {
              id: 'band-axis',
              dataKey: chartProps('date'),
              tickNumber: chartDataset.length,
              tickPlacement: 'middle',
              scaleType: 'band',
              tickLabelStyle: { angle: -35 },
              valueFormatter: yearFormatter,
            },
          ]}
          yAxis={[{ label: yAxisTitle, min: 0, max: maxLeftYValue }]}
          additionalLegend={[intensityTargetLegend]}
        ></MuiChartContainerWithLegend>
      </Box>
    </CardContainer>
  );
};
