import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import {
  DropdownGroup,
  DropdownGroupingCategory,
  handleGroupingsUpdate,
} from './models/vesselGroupings.viewModel';
import { useGetAllVesselGroupings } from './services/vesselGroupings.get.services';
import { FormControlStyles, SelectStyles } from './styles/styles';
import { VesselGroupingsDropdownCategory } from './vesselGroupingsDropdownCategory.component';
import { DownloadListVessel } from 'routes/reports/components/generateReport/download-common';

export function VesselGroupingsDropdown(): EmotionJSX.Element {
  const { id } = useParams();
  const { setVesselGroup, setVesselGroups, setVessels } =
    useContext(TopNavigationContext);
  const { setImoNumbers } = useContext(NavBarContext);
  const [vesselGroupings, setGroupings] = useState<DropdownGroupingCategory[]>(
    []
  );
  const [selectedGroupings, setSelectedGroupings] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<
    DropdownGroupingCategory[]
  >([]); // Used purely to help MUI's Select stop crying

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const vesselsResponse = useGetAllVesselGroupings();
  const loading = vesselsResponse.loading;

  useEffect(() => {
    if (vesselsResponse.data !== null && vesselsResponse.data.length > 0) {
      setGroupings(vesselsResponse.data);
      const allVessels: DownloadListVessel[] = getAllVessels(
        vesselsResponse.data
      );
      setVessels(allVessels);
      setImoNumbers(allVessels.map((x) => x.imoNumber));
    }
  }, [loading, setImoNumbers, setVessels, vesselsResponse.data]);

  function getAllVessels(
    vesselGroupings: DropdownGroupingCategory[]
  ): DownloadListVessel[] {
    const allVessels: DownloadListVessel[] = [];
    const uniqueVesselIds = new Set<string>();

    vesselGroupings.forEach((obj: DropdownGroupingCategory) => {
      if (obj.groupings && Array.isArray(obj.groupings)) {
        obj.groupings.forEach((group: DropdownGroup) => {
          if (group.vessels && Array.isArray(group.vessels)) {
            group.vessels.forEach((vessel: DownloadListVessel) => {
              if (vessel.id && !uniqueVesselIds.has(vessel.id)) {
                uniqueVesselIds.add(vessel.id);
                allVessels.push(vessel);
              }
            });
          }
        });
      }
    });

    return allVessels;
  }

  useEffect(() => {
    if (id) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [id]);
  // TODO: solve warning (maybe refactor to use something other than useEffect, or process result somewhere else?) since setGroupings is a state update, it causes a useEffect render loop when vesselsResponse.data is added as a dependency. The result needs to be stored in state because it's used by the dropdown to manage checkboxes etc.
  const updateGroupings = (grouping: DropdownGroupingCategory) => {
    const {
      filteredGroupings,
      newSelectedGroupings,
      selectedOptions,
      selectedImoNumbers,
      selectedVessels,
    } = handleGroupingsUpdate(grouping, vesselGroupings);
    setGroupings(filteredGroupings);
    setSelectedGroupings(newSelectedGroupings.join(', '));
    setSelectedOptions(selectedOptions);
    setImoNumbers(selectedImoNumbers);
    setVessels(selectedVessels);
  };
  useEffect(() => {
    if (selectedOptions.length === 0) {
      const allVessels: DownloadListVessel[] = getAllVessels(vesselGroupings);
      setVessels(allVessels);
    }
  }, [selectedOptions, vesselGroupings, setVessels]);
  useEffect(() => {
    setVesselGroup(selectedGroupings);
    setVesselGroups(selectedOptions);
  }, [setVesselGroup, setVesselGroups, selectedGroupings, selectedOptions]);

  return (
    <FormControl sx={FormControlStyles} size='small'>
      <InputLabel
        id='vessel-group-label'
        sx={{
          variant: 'inputLabel',
        }}
      >
        Vessel Group
      </InputLabel>
      <Select
        labelId='vessel-group-label'
        id='vessel-group'
        multiple
        value={selectedOptions}
        renderValue={() => selectedGroupings}
        label='Vessel Group'
        sx={SelectStyles}
        disabled={isDisabled}
        IconComponent={KeyboardArrowDownSharpIcon}
      >
        {loading ? (
          <MenuItem>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>Loading...</div>
              <Box sx={{ height: '24px' }}>
                <CircularProgress sx={{ mt: 0.6 }} size={16} />
              </Box>
            </Box>
          </MenuItem>
        ) : (
          vesselGroupings.map((vesselGrouping) => (
            <VesselGroupingsDropdownCategory
              vesselGrouping={vesselGrouping}
              updateGroupings={updateGroupings}
              key={`vessel-grouping-${vesselGrouping.id}`}
            />
          ))
        )}
      </Select>
    </FormControl>
  );
}
