import { IRagFilterData } from 'routes/dashboard/models/fleet-kpis.model';
import { EmptyOption } from 'shared/components/inputs/input-fields';
import { PortData } from 'shared/utils/portpolygons-util';

export enum SequenceTypes {
  AtSea = 'At Sea',
  InPortLoading = 'In Port - Loading',
  InPortDischarge = 'In Port - Discharge',
  Anchorage = 'Idle - Anchorage',
  Drifting = 'Idle - Drifting',
  Maneuvering = 'Maneuvering',
}

export enum SequenceTypesDto {
  AtSea = 'AT_SEA',
  InPortLoading = 'IN_PORT_LOADING',
  InPortDischarge = 'IN_PORT_DISCHARGE',
  Anchorage = 'ANCHORAGE',
  Drifting = 'DRIFTING',
  Maneuvering = 'MANEUVERING',
}

export enum LoadingConditionTypes {
  Ballast = 'Ballast',
  Laden = 'Laden',
}

export interface ISequence {
  id: number;
  sequence?: SequenceTypes | EmptyOption;
  loadingCondition?: LoadingConditionTypes | EmptyOption;
  port?: PortData;
  distance?: number;
  speed?: number;
  duration?: number;
  fuelType?: IFuelDto[];

  cargoQuantity?: number;
  sequenceFailingField?: keyof ISequence | undefined;
  hasError?: boolean;
}

export interface ISequenceDto {
  id: number;
  sequenceType?: string;
  loadingCondition?: LoadingConditionTypes | EmptyOption;
  portName?: string;
  portCode?: string;
  distance?: number;
  speed?: number;
  duration?: number;
  fuelType?: string;

  cargoQuantity?: number;
}

export interface ISequenceDto {
  id: number;
  sequenceType?: string;
  loadingCondition?: LoadingConditionTypes | EmptyOption;
  portName?: string;
  portCode?: string;
  distance?: number;
  speed?: number;
  duration?: number;
  fuel?: IFuelDto[];
  cargoQuantity?: number;
}

export interface IFuelDto {
  id: number;
  fuelType?: string;
  fuelConsumption?: number;
}

export interface IPlannedItinerary {
  id: number | undefined;
  itineraryName: string;
  lastPortOfCall?: PortData;
  sequences: ISequence[];
}

export interface IPlannedItineraryDto {
  id: number;
  name: string;
  lastPortOfCall: string;
  lastPortOfCallCode: string;
  sequences: ISequenceDto[];
}

export interface IPlannedItineraryResults {
  id: number;
  itineraryName: string;
  departurePort: PortData;
  durationAtSea: number;
  durationInPort: number;
  idlePercentage: number;
  idlePercentageVsYtdIdlePercentage: number;
  idleDuration: number;
  maneuveringDuration: number;
  timeLaden: number;
  timeBallast: number;
  avgSpeed: number;
  avgSpeedVsYtdAvgSpeed: number;
  avgSpeedLaden: number;
  avgSpeedBallast: number;
  co2AtSea: number;
  co2InPort: number;
  co2Idle: number;
  co2Maneuvering: number;
  co2Laden: number;
  co2Ballast: number;
  arrivalPort: PortData;
  totalDistance: number;
  totalCO2: number;
  requiredCii: number;
  attainedCii: number;
  cii: IRagFilterData;
  ytdCii: number;
  ciiDelta: number;
  ciiDeviation: IRagFilterData;
  estimatedCii: number;
  ciiRating: IRagFilterData;
  eoyCii: number;
  eoyCiiRating: string;
  eoyCiiDeviation: IRagFilterData;
  eoyCiiPercentage: number;

  eeoi: number;
  eeoiDelta: number;
  eeoiDeviation: IRagFilterData;
  eeoiTarget: number;

  eua: number;
  euaDelta: number;
  euaDeviation: number;
  ghgIntensity: number;
  complianceBalance: number;
  fuelEUPenalty: number;
  euaPlanned: number;
  ghgMrv: number;
  ghgMrvRemarks?: string;
  co2Mrv: number;
  n2OMrv: number;
  ch4Mrv: number;
  fuelSlippage: number;
  estGhgMrv: number;
  euaStatus: IRagFilterData;
  ghgStatus: IRagFilterData;
  fuelEuStatus: IRagFilterData;
  // original planned itinerary inputs
  plannedItinerary?: IPlannedItinerary;
}

export const emptyItinerary: IPlannedItinerary = {
  id: 0,
  itineraryName: '',
  sequences: [],
};
