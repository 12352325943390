import { ReactElement, createContext, useContext, useState } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { ExportButton } from '../export-button/export-button.component';
import { ExpandMore } from './expand-more/expand-more.component';
import { Avatar, SxProps } from '@mui/material';
import { SectionName } from 'shared/utils/valid-sections';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { NavBarContext } from 'shared/models/navBar.context.model';

export interface ExportOptions {
  includePDF?: boolean;
  alertType?: string;
  includeCardData?: boolean;

  // these are the options that will be used to generate the filename
  filenameOptions?: {
    includeSectionName?: boolean;
    includeVesselGroup?: boolean;
    includeDateRange?: boolean;
    includeVesselName?: boolean;
  };

  // this are the introductory rows that will be added to the excel file
  introductoryRows?: {
    includeVesselGroup?: boolean;
    includeDateRange?: boolean;
    includeVesselName?: boolean;
  };
}

// these are the default export options
const defaultExportOptions: ExportOptions = {
  includePDF: false,
  filenameOptions: {
    includeSectionName: true,
    includeVesselGroup: true,
    includeDateRange: true,
    includeVesselName: false,
  },
  introductoryRows: {
    includeVesselGroup: true,
    includeDateRange: true,
    includeVesselName: false,
  },
};

export interface SectionProps {
  id?: string;
  title: string;
  customButtons?: ReactElement;
  childComponent?: ReactElement;
  exportSection?: boolean;
  exportOptions?: ExportOptions;
  expandable?: boolean;
  preventUnmount?: boolean;
  sx?: SxProps;
}

const getIntroductoryRowsData = ({
  props,
  dateRange,
  vesselGroup,
  vesselName,
}: {
  props: SectionProps;
  dateRange: string;
  vesselGroup: string[];
  vesselName: string[];
}) => {
  const introductoryRowsData: string[][] = [];
  if (props.exportOptions?.introductoryRows?.includeVesselGroup !== false) {
    introductoryRowsData.push(['Vessel Group', vesselGroup.toString()]);
  }

  if (props.exportOptions?.introductoryRows?.includeDateRange !== false) {
    introductoryRowsData.push(['Date Range', dateRange.toString()]);
  }

  if (props.exportOptions?.introductoryRows?.includeVesselName) {
    introductoryRowsData.push(['Vessel Name', vesselName.toString()]);
  }

  if (props.exportOptions?.alertType) {
    const alertType = props.exportOptions?.alertType;
    introductoryRowsData.push([
      'Alert Type',
      alertType?.charAt(0).toUpperCase() + alertType?.slice(1),
    ]);
  }

  return introductoryRowsData;
};

export const sectionContext = createContext('');

export const Section = (props: SectionProps): EmotionJSX.Element => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const exportOptions = {
    ...defaultExportOptions,
    ...props.exportOptions,
    filenameOptions: {
      ...defaultExportOptions.filenameOptions,
      ...props.exportOptions?.filenameOptions,
    },
    introductoryRows: {
      ...defaultExportOptions.introductoryRows,
      ...props.exportOptions?.introductoryRows,
    },
  };

  const textColor = theme.palette.text.primary;
  const paperColor = theme.background.page;

  const { vesselGroup, vesselName } = useContext(TopNavigationContext);
  const { utcDateRange } = useContext(NavBarContext);
  const dateRange = utcDateRange?.formatDMY();
  const introductoryRowsData = getIntroductoryRowsData({
    props,
    dateRange: dateRange || '',
    vesselGroup,
    vesselName,
  });

  const collapseStyle: SxProps =
    props.expandable === false
      ? { height: '100%', overflowY: 'auto' }
      : { paddingBottom: 3 };

  return (
    <sectionContext.Provider value={props.id ?? ''}>
      <Box
        component={'section'}
        sx={{
          backgroundColor: paperColor,
          marginBottom: 3,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
        }}
      >
        <Card
          elevation={0}
          sx={{ overflow: 'visible', ...props.sx, backgroundColor: paperColor }}
        >
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              top: 0,
              position: 'sticky',
              backgroundColor: theme.background.page,
              zIndex: 100,
            }}
          >
            <Typography variant='h2'>{props.title}</Typography>
            <CardActions>
              {props.exportSection && (
                <ExportButton
                  sectionName={(props.id ?? props.title) as SectionName}
                  exportOptions={exportOptions}
                  introductoryRowsData={introductoryRowsData}
                />
              )}
              {props.customButtons}
              {props.expandable !== false && (
                <ExpandMore expand={expanded} onClick={handleExpandClick}>
                  <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                    <KeyboardArrowDownSharpIcon
                      sx={{
                        fontSize: 24,
                        color: theme.palette.text.primary,
                        borderRadius: '50%',
                      }}
                    />
                  </Avatar>
                </ExpandMore>
              )}
            </CardActions>
          </Toolbar>
          <Divider sx={{ mt: 0, backgroundColor: textColor }} />
          <Collapse
            in={expanded}
            timeout='auto'
            unmountOnExit={!props.preventUnmount}
            sx={collapseStyle}
          >
            <CardContent>{props.childComponent}</CardContent>
          </Collapse>
        </Card>
      </Box>
    </sectionContext.Provider>
  );
};
