import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { KpiData, footerType } from '../status-kpi-card.component';
import { typography } from 'styles/typography';
import { formatNumber } from 'shared/utils/float-utils';
import { isValidElement } from 'react';

export interface KpiCardFooterProps {
  footerType?: footerType;
  kpiData: KpiData | undefined;
  footerUnits?: string | undefined;
  footerSize?: string | undefined;
  footerUnits2?: string | undefined;
  footerUnitBeforeValue?: boolean | undefined;
  footerUnit2BeforeValue?: boolean | undefined;
}

const renderCardFooter = (
  config: Array<{
    label: string;
    value: any;
    unit: string;
    labelBeforeValue: boolean | undefined;
  }>
) => {
  const { fontFamilies, fontWeights, lineHeights, fontSize, letterSpacing } =
    typography;

  const subscriptStyle: SxProps = {
    alignSelf: 'end',
    ml: 0.5,
    fontFamily: fontFamilies.roboto,
    fontSize: fontSize['1'],
    fontWeight: fontWeights['roboto-2'],
    letterSpacing: letterSpacing['5'],
    lineHeight: lineHeights['7'],
  };

  return (
    <Box component={'section'} sx={{ width: '100%' }}>
      {config.map((item, index) => (
        <Box key={index} sx={{ display: 'flex', paddingTop: 2 }}>
          <Typography variant='body1' sx={{ pr: 2 }}>
            {item.label}
          </Typography>
          {item.labelBeforeValue !== true && (
            <Typography variant='body1' sx={{ ml: 'auto' }}>
              {item.value}
            </Typography>
          )}
          {item.labelBeforeValue !== true && (
            <Typography sx={subscriptStyle}>{item.unit}</Typography>
          )}
          {item.labelBeforeValue === true && (
            <Typography sx={{ ml: 'auto' }}>{item.unit}</Typography>
          )}
          {item.labelBeforeValue === true && (
            <Typography variant='body1'>{item.value}</Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export const getCardTitles = (props: KpiCardFooterProps) => {
  let title = '';
  let subTitle = '';
  let noDecimals = 2;
  const footerType: footerType = props.footerType;

  // check if this is a custom footer
  if (Array.isArray(footerType)) {
    const customFooter = footerType;

    return customFooter.map((item, index) => {
      const footerUnits =
        index === 1 && props.footerUnits2 != null
          ? props.footerUnits2
          : props.footerUnits;
      const labelBeforeValue =
        index === 1
          ? props.footerUnit2BeforeValue
          : props.footerUnitBeforeValue;
      return {
        title: item.title,
        value: item.value,
        titleUnits: item.unitLabel ?? footerUnits ?? '',
        noDecimals: noDecimals,
        labelBeforeValue,
      };
    });
  }

  switch (footerType) {
    case 'required':
      title = 'Attained';
      subTitle = 'Required';
      break;

    case 'target':
      title = 'Actual';
      subTitle = 'Target';
      break;

    case 'planned':
      title = 'Liability';
      subTitle = 'Planned';
      noDecimals = 0;
      break;

    case 'vessel-fleet':
      title = 'Vessel';
      subTitle = 'Fleet';
      noDecimals = 0;
      break;

    case 'eua-planned':
      title = 'Actual';
      subTitle = 'Planned';
      noDecimals = 0;
      break;
  }

  return [
    {
      title: title,
      value: undefined,
      titleUnits: props.footerUnits ?? '',
      noDecimals: noDecimals,
      labelBeforeValue: undefined,
    },
    {
      title: subTitle,
      value: undefined,
      titleUnits: props.footerUnits ?? '',
      noDecimals: noDecimals,
      labelBeforeValue: undefined,
    },
  ];
};

export const KPICardFooter = (props: KpiCardFooterProps) => {
  const getCard = (props: KpiCardFooterProps) => {
    if (props.footerType && isValidElement(props.footerType)) {
      return props.footerType;
    }
    const settings = getCardTitles(props);
    const titleValue = props.kpiData?.secondaryValue;
    const data = [
      {
        label: settings[0]?.title ?? '',
        value:
          settings[0]?.value ??
          formatNumber(titleValue, settings[0]?.noDecimals),
        unit: settings[0]?.titleUnits ?? '',
        labelBeforeValue: settings[0]?.labelBeforeValue,
      },
      {
        label: settings[1]?.title ?? '',
        value:
          settings[1]?.value ??
          formatNumber(props.kpiData?.tertiaryValue, settings[1]?.noDecimals),
        unit: settings[1]?.titleUnits ?? '',
        labelBeforeValue: settings[1]?.labelBeforeValue,
      },
    ];

    if (settings.length > 2) {
      data.push({
        label: settings[2]?.title ?? '',
        value: settings[2]?.value,
        unit: settings[2]?.titleUnits ?? '',
        labelBeforeValue: settings[2]?.labelBeforeValue,
      });
    }

    return renderCardFooter(data);
  };

  return <>{getCard(props)}</>;
};
