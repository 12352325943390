import { RAGDisplayProps } from 'shared/components/status-card/ragIndicator.component';
import { UTCDate } from 'shared/utils/date-utc-helper';

export interface IFleetUtilizationDetail {
  count: number;
  countPercentage: number;
  duration: number;
  durationPercentage: number;
}

export interface IFleetUtilization {
  [key: string]: IFleetUtilizationDetail;
}

export enum FleetUtilizationType {
  LADEN = 'laden',
  BALLAST = 'ballast',
  IDLE = 'idle',
  IN_PORT = 'inPort',
  AT_SEA = 'atSea',
  MANEUVERING = 'maneuvering',
}

export interface IFleetUtilizationAndOperationalProfiles {
  id?: string;
  VesselName: string;
  CII?: RAGDisplayProps;
  Laden?: number;
  Ballast?: number;
  AtSea?: number;
  InPort?: number;
  Maneuvering?: number;
  Idle?: number;
  ImoNumber?: number;
}

export type FuelEUGhgIntensityChartProps = {
  date: Date;
  intensity: number;
  intensityTarget: number;
  complianceBalance: number;
  fuelEUPenalty: number;
  fossilFuel: number;
  bioFuel: number;
  eFuel: number;
};

export type DataPoint = FuelEUGhgIntensityChartProps & {
  isFutureProjection: boolean;
};

// DataPoint can only uses "Date" to render the chart, but the mapper/GQL uses UTCDate
export type DataPointWithUTCDate = Omit<DataPoint, 'date'> & { date: UTCDate };
