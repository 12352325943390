import { DashboardVessel, KpiStatus } from '_gql/graphql';
import { IFleetKPITableData, IRagFilterData } from '../models/fleet-kpis.model';
import { TEXT } from 'shared/constants/text';
import { convertKpiStatusToStatus } from 'shared/utils/status-utils';
import {
  NA_VALUE_INDICATOR,
  NO_VALUE_INDICATOR,
} from 'shared/components/datagrid/DataGrid.component';
import { Status as RAGStatus } from 'shared/components/rag-filter/filter.hook';
import { VoyageComparisonMapper } from 'routes/environmental-monitor/mappers/voyage-comparison.mapper';
export class FleetKpiDashboardTableMapper {
  public static fromDTO(dto?: DashboardVessel) {
    const fleetKpiDashboardTableData = {} as IFleetKPITableData;

    fleetKpiDashboardTableData.id = dto?.vesselId ?? NO_VALUE_INDICATOR;
    fleetKpiDashboardTableData.vesselImo = dto?.imoNumber ?? NO_VALUE_INDICATOR;

    fleetKpiDashboardTableData.VesselName = dto?.name ?? NO_VALUE_INDICATOR;

    fleetKpiDashboardTableData.SisterClass =
      dto?.sisterClass ?? NO_VALUE_INDICATOR;

    fleetKpiDashboardTableData.CII = this.convertToRagFilterFormat({
      value: dto?.kpis?.cii?.ciiRating ?? NO_VALUE_INDICATOR,
      status: dto?.kpis?.cii?.status?.toLowerCase() ?? RAGStatus.unknown,
    });
    fleetKpiDashboardTableData.GhgIntensity = this.convertToRagFilterFormat({
      value: dto?.kpis?.fuelEU?.ghgIntensity ?? NO_VALUE_INDICATOR,
      status:
        dto?.kpis?.fuelEU?.ghgIntensityStatus?.toLowerCase() ??
        RAGStatus.unknown,
    });
    fleetKpiDashboardTableData.FuelEUPenalty =
      dto?.kpis?.fuelEU?.fuelPenalty ?? undefined;
    fleetKpiDashboardTableData.EEOI = this.convertToRagFilterFormat({
      value: dto?.kpis?.eeoi?.deviation ?? NO_VALUE_INDICATOR,
      status: dto?.kpis?.eeoi?.status?.toLowerCase() ?? RAGStatus.unknown,
    });

    fleetKpiDashboardTableData.EUA = this.convertToRagFilterFormat({
      value:
        dto?.kpis?.euaAgainstPlanned?.value === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.kpis?.euaAgainstPlanned?.value ?? NA_VALUE_INDICATOR,
      status:
        convertKpiStatusToStatus(
          dto?.kpis?.euaAgainstPlanned?.status || KpiStatus.Unknown
        ) ?? RAGStatus.unknown,
    });
    fleetKpiDashboardTableData.vesselEuaPlannedValue =
      dto?.kpis?.euaAgainstPlanned?.vesselEuaPlannedValue ?? undefined;

    fleetKpiDashboardTableData.DeltaPower = this.convertToRagFilterFormat({
      value:
        dto?.performanceIndicators?.deltaPower?.value?.deltaPower ??
        NO_VALUE_INDICATOR,
      status:
        dto?.performanceIndicators?.deltaPower?.value?.status?.toLowerCase() ??
        RAGStatus.unknown,
    });

    fleetKpiDashboardTableData.DeltaSpeed = this.convertToRagFilterFormat({
      value:
        dto?.performanceIndicators?.deltaSpeed?.value?.deltaSpeed ??
        NO_VALUE_INDICATOR,
      status:
        dto?.performanceIndicators?.deltaSpeed?.value?.status?.toLowerCase() ??
        RAGStatus.unknown,
    });

    fleetKpiDashboardTableData.FoulingResistance =
      this.convertToRagFilterFormat({
        value:
          dto?.performanceIndicators?.foulingResistance?.value
            ?.rahFoulingPercent ?? NO_VALUE_INDICATOR,
        status:
          dto?.performanceIndicators?.foulingResistance?.value?.status?.toLowerCase() ??
          RAGStatus.unknown,
      });

    fleetKpiDashboardTableData.MainEngine = this.convertToRagFilterFormat({
      value:
        dto?.performanceIndicators?.mesfoc?.value?.sfocDeviationPercent ??
        NO_VALUE_INDICATOR,
      status:
        dto?.performanceIndicators?.mesfoc?.value?.status?.toLowerCase() ??
        RAGStatus.unknown,
    });

    fleetKpiDashboardTableData.PassedScore = this.convertToRagFilterFormat({
      value: dto?.reports?.overallPassScorePercentage ?? NO_VALUE_INDICATOR,
      status: dto?.reports?.status?.toLowerCase() ?? RAGStatus.unknown,
    });

    fleetKpiDashboardTableData.FlaggedReports =
      dto?.reports?.overallFailScore ?? NO_VALUE_INDICATOR;

    fleetKpiDashboardTableData.ghgMrv = VoyageComparisonMapper.ghgMrvFromDTO(
      dto?.kpis?.ghgMrv ?? undefined
    );

    return fleetKpiDashboardTableData;
  }

  private static convertToRagFilterFormat(dto: any): IRagFilterData {
    const ragFilterData: IRagFilterData = {
      status: dto.status,
      value: dto.value,
    };

    return ragFilterData;
  }
}
