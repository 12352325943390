import { Grid, Typography } from '@mui/material';
import { AllSeriesType, AxisConfig, ChartsXAxisProps } from '@mui/x-charts-pro';

import { FuelConsumption, FuelUsage } from '_gql/graphql';
import React from 'react';
import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import {
  MuiChartContainer,
  getTooltipDataIndex,
  tooltipContentProps,
} from 'shared/components/mui-chart/container.component';
import {
  StatusKpiCardProps,
  customFooterType,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';
import { TEXT } from 'shared/constants/text';
import { formatNumber } from 'shared/utils/float-utils';

const fuelColors = ['#319DB4', '#8C85CF', '#FF598C', '#ffffff'];

const customTooltipTitle = (
  props: tooltipContentProps,
  fuelData: FuelUsage[]
) => {
  const index = getTooltipDataIndex(props);
  if (index === undefined) return null;

  const data = fuelData[index];
  const title = `${data?.classification} (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`;
  const total = formatNumber(data?.total);
  return (
    <Grid container sx={{ width: '200px' }}>
      <Grid item xs={6}>
        <Typography fontWeight={'bold'}>{title}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align='right' fontWeight={'bold'}>
          {total}
        </Typography>
      </Grid>
    </Grid>
  );
};

const customTooltipContent = (
  props: tooltipContentProps,
  fuelData: FuelUsage[]
) => {
  const index = getTooltipDataIndex(props);
  if (index === undefined) return null;

  const data = fuelData[index];
  return (
    <Grid container sx={{ width: '200px' }}>
      {data?.consumptions?.map((consumption: FuelConsumption) => (
        <React.Fragment key={consumption.name}>
          <Grid item xs={6}>
            {consumption.name}
          </Grid>
          <Grid item xs={6}>
            <Typography align='right'>
              {formatNumber(consumption.consumptionMt, 0)}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export const FuelUsageChart = (
  fuelData: FuelUsage[],
  featureFlags: { [key: string]: boolean }
): StatusKpiCardProps => {
  const dataSet = fuelData.map((item) => {
    return {
      classification: item.classification,
      total: item.total,
    };
  });
  const exportItems = dataSet.map((item) => {
    return {
      title: `${item.classification} (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      value: formatNumber(item.total, 0),
    };
  }) as customFooterType;
  const fleetFuelUsageKpiCardEnabled =
    featureFlags[FEATURE_FLAG.FLEETOVERVIEW_FUEL_USAGE_KPI_CARD];
  const maxTotal = Math.max(...dataSet.map((item) => item.total ?? 0));
  const series = [{ dataKey: 'total', type: 'bar' }] as AllSeriesType<'bar'>[];

  // tooltip
  const tooltipTitle = (props: tooltipContentProps) =>
    customTooltipTitle(props, fuelData);
  const tooltipContent = (props: tooltipContentProps) =>
    customTooltipContent(props, fuelData);

  return {
    title: 'Fuel Usage (EU)',
    titleAlignment: 'center',
    hideFooterDivider: true,
    footerType: (
      <MuiChartContainer
        dataset={dataSet}
        series={series}
        height={170}
        margin={{ top: 15, right: 30, left: 30, bottom: 50 }}
        tooltip={{
          title: tooltipTitle,
          content: tooltipContent,
        }}
        sectionVisibility={{
          highlight: false,
          legend: false,
          grid: false,
          markers: false,
          axisY: false,
        }}
        yAxis={[{ max: maxTotal }]}
        xAxis={[
          {
            dataKey: 'classification',
            scaleType: 'band',
            tickLabelStyle: { angle: -35 },
            categoryGapRatio: 0.6,
            colorMap: {
              type: 'ordinal',
              colors: fuelColors,
            },
          } as AxisConfig<'band', string, ChartsXAxisProps>,
        ]}
      ></MuiChartContainer>
    ),
    kpiData: {
      primaryValue: '',
      status: maxTotal > 0 ? Status.graph : Status.none,
    },
    customExportDefinitions: exportItems,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
    isFeatureFlagEnabled: fleetFuelUsageKpiCardEnabled ?? true,
  };
};
