import { Box, Grid } from '@mui/material';
import { ChartsItemContentProps } from '@mui/x-charts-pro';
import { nameof } from 'shared/components/datagrid';
import { getTooltipDataIndex } from 'shared/components/mui-chart/container.component';
import { formatNumber } from 'shared/utils/float-utils';
import { TEXT } from 'shared/constants/text';
import { FuelEUGhgIntensityChartProps } from 'routes/environmental-monitor/models/fleet.model';
import { FuelEuGhgIntensityTrendChartKeyItems } from 'routes/environmental-monitor/view-models/fuel-eu-ghg-intensity-usage-trend-chart.viewModel';
import { UTCDate } from 'shared/utils/date-utc-helper';

const chartProps = nameof<FuelEUGhgIntensityChartProps>;

const toolTipStyles = {
  width: '350px',
  labelWidth: 6,
  valueWidth: 6,
  unitFontSize: '12px',
};

const UnitBox = (props: { unit: string }) => (
  <Box component={'span'} sx={{ fontSize: toolTipStyles.unitFontSize }}>
    {props.unit}
  </Box>
);

const fuelTooltip = (data: FuelEUGhgIntensityChartProps | undefined) => (
  <Grid container sx={{ width: '200px' }}>
    <Grid item xs={toolTipStyles.labelWidth}>
      {FuelEuGhgIntensityTrendChartKeyItems['fossilFuel'].name}
    </Grid>
    <Grid item xs={toolTipStyles.valueWidth} textAlign={'right'}>
      {`${formatNumber(data?.fossilFuel, 2)} ${
        TEXT.UNIT_MEASUREMENT.METRIC_TONNES
      }`}
    </Grid>
    <Grid item xs={toolTipStyles.labelWidth}>
      {FuelEuGhgIntensityTrendChartKeyItems['bioFuel'].name}
    </Grid>
    <Grid item xs={toolTipStyles.valueWidth} textAlign={'right'}>
      {`${formatNumber(data?.bioFuel, 2)} ${
        TEXT.UNIT_MEASUREMENT.METRIC_TONNES
      }`}
    </Grid>
    <Grid item xs={toolTipStyles.labelWidth}>
      {FuelEuGhgIntensityTrendChartKeyItems['eFuel'].name}
    </Grid>
    <Grid item xs={toolTipStyles.valueWidth} textAlign={'right'}>
      {`${formatNumber(data?.eFuel, 2)} ${TEXT.UNIT_MEASUREMENT.METRIC_TONNES}`}
    </Grid>
  </Grid>
);

const intensityTargetTooltip = (
  data: FuelEUGhgIntensityChartProps | undefined
) => (
  <Grid container sx={{ width: toolTipStyles.width }}>
    <Grid item xs={toolTipStyles.labelWidth}>
      GHG Intensity Target
    </Grid>
    <Grid item xs={toolTipStyles.valueWidth} textAlign={'right'}>
      {`${formatNumber(data?.intensityTarget, 2)} `}
      <UnitBox unit={TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE} />
    </Grid>
  </Grid>
);

const intensityTooltip = (data: FuelEUGhgIntensityChartProps | undefined) => (
  <Grid container sx={{ width: toolTipStyles.width }}>
    <Grid item xs={toolTipStyles.labelWidth}>
      GHG Intensity Target
    </Grid>
    <Grid item xs={toolTipStyles.valueWidth} textAlign={'right'}>
      {`${formatNumber(data?.intensityTarget, 2)} `}
      <UnitBox unit={TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE} />
    </Grid>
    <Grid item xs={toolTipStyles.labelWidth}>
      GHG Intensity
    </Grid>
    <Grid item xs={toolTipStyles.valueWidth} textAlign={'right'}>
      {`${formatNumber(data?.intensity, 2)} `}
      <UnitBox unit={TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE} />
    </Grid>
    <Grid item xs={toolTipStyles.labelWidth}>
      Compliance Balance
    </Grid>
    <Grid item xs={toolTipStyles.valueWidth} textAlign={'right'}>
      {`${formatNumber(data?.complianceBalance, 0)} `}
      <UnitBox unit={TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ} />
    </Grid>
    <Grid item xs={toolTipStyles.labelWidth}>
      FuelEU Penalty
    </Grid>
    <Grid item xs={toolTipStyles.valueWidth} textAlign={'right'}>
      {TEXT.CURRENCY.EURO}
      {formatNumber(data?.fuelEUPenalty)}
    </Grid>
  </Grid>
);

export function fuelEuGhgIntensityUsageTooltip(
  params: ChartsItemContentProps<any>,
  chartDataset: FuelEUGhgIntensityChartProps[]
) {
  const hoveredItem = params.itemData.seriesId;
  const index = getTooltipDataIndex(params);

  // if the dataset or the itemData is not available, return null
  if (!chartDataset || index === undefined) return null;
  const data = chartDataset[index];
  switch (hoveredItem) {
    case chartProps('intensity'):
      return intensityTooltip(data);
    case chartProps('intensityTarget'):
      return intensityTargetTooltip(data);
    case chartProps('fossilFuel'):
      return fuelTooltip(data);
    case chartProps('bioFuel'):
      return fuelTooltip(data);
    case chartProps('eFuel'):
      return fuelTooltip(data);
    default:
      return null;
  }
}

export function fuelEuGhgIntensityUsageTooltipTitle(
  params: ChartsItemContentProps<any>,
  chartDataset: FuelEUGhgIntensityChartProps[],
  startDate: UTCDate | undefined
) {
  const index = getTooltipDataIndex(params);

  if (!chartDataset || index === undefined) return null;

  const data = chartDataset[index];
  const utcDate = new UTCDate(data?.date);
  const currentYear = new UTCDate().date?.getUTCFullYear() ?? 0;

  if (utcDate.date?.getUTCFullYear() === currentYear) {
    if (
      utcDate.date?.getUTCFullYear() === startDate?.date?.getUTCFullYear() &&
      utcDate.date?.getUTCMonth() === startDate?.date?.getUTCMonth()
    ) {
      return `${utcDate.formatMY()}`;
    } else {
      return `${startDate?.formatMY()} - ${utcDate.formatMY()}`;
    }
  }

  return null;
}
